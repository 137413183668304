import React from 'react';  
import { Link } from "react-router-dom";
import {  AiOutlineMail} from 'react-icons/ai';

import moment from 'moment';
import firebase from 'firebase';
import { FiPhone} from 'react-icons/fi';
import Logo from '../assets/logo.jpg';

class Register extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            mm: false,
            gf: false,
            files: null,
            fname: "",
            mname: "",
            lname: "",
            dob: "",
            pob: "",
            pa: "",
            ra: "",
            email: "",
            phone: "",
            id: "",
            idnum: "",
            imre: "",
            promo: "",
            dep: "",
            pay: "",
            branch: "",
            isLoading: "",
            ems: false,
            polo: false,
            imrp: "",
            dis: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
      }

    handleChange = (files) => {
        this.setState({
            files: files
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
    return (
        <div className="App">
            <header>
                <div className="header">
                    <div className="logo"></div>
                    <div className="nb">
                        <Link to="/">Home</Link>
                        <Link to="/Gallery">Gallery</Link>
                        <Link to="/About">About Us</Link>
                        <Link to="/Contact">Contact</Link>
                        <Link to="/Register" className="bv">Register Now</Link>
                    </div>
                    <div className="menu" onClick={() => this.setState({gf: true})}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </header>
            {
                this.state.gf ?
                    <div className="jkf">
                        <div className="close">
                            <div></div>
                            <div onClick={() => this.setState({gf: false})}>x</div>
                        </div>
                        <div className="nb">
                            <Link to="/">Home</Link>
                            <Link to="/Gallery">Gallery</Link>
                            <Link to="/About">About Us</Link>
                            <Link to="/Contact">Contact</Link>
                            <Link to="/Register" className="bv">Register Now</Link>
                        </div>
                    </div>
                :null
            }
            <div className="kvf">
                <div className="qz">Register</div>
            </div>
            <div className="reg">
                {
                    this.state.ems ?
                    <div style={{width: "100%", textAlign: 'center', fontSize: 30, color: "#f00"}}>Please Enter All Fields</div>:null
                }
                <form>
                    <div className="dob2">
                        <input type="file" onChange={(e) => {this.handleChange(e.target.files)}} />
                    </div>
                    <div className="name">
                        <input type="text" onChange={(event) => {this.setState({fname: event.target.value})}} placeholder="First Name*" />
                        <input type="text" onChange={(event) => {this.setState({mname: event.target.value})}} placeholder="Middle Name" />
                        <input type="text" onChange={(event) => {this.setState({lname: event.target.value})}} placeholder="Last Name*" />
                    </div>
                    <div className="dob">
                        <div style={{width: "47%"}}>
                            <div>Date Of Birth*</div>
                            <input style={{width: "100%"}} type="date" onChange={(event) => {this.setState({dob: event.target.value})}} />
                        </div>

                        <input type="text" onChange={(event) => {this.setState({pob: event.target.value})}} placeholder="Place Of Birth*" />
                    </div>
                    <div className="dob2">
                        <input type="text" onChange={(event) => {this.setState({pa: event.target.value})}} placeholder="Postal Address" />
                    </div>
                    <div className="dob2">
                        <input type="text" onChange={(event) => {this.setState({ra: event.target.value})}} placeholder="Residential Address*" />
                    </div>
                    <div className="dob">
                        <input type="email" onChange={(event) => {this.setState({email: event.target.value})}} placeholder="Email*" />
                        <input type="text" onChange={(event) => {this.setState({phone: event.target.value})}} placeholder="Telephone Number*" />
                    </div>
                    <div className="dob2">
                        <select onChange={(event) => {this.setState({branch: event.target.value})}} value={this.state.value}>
                            <option value="" disabled selected>Select Branch*</option>
                            <option value="Ejisu">Ejisu</option>
                            <option value="Abuakwa">Abuakwa</option>
                        </select>
                    </div>
                    <div className="dob2">
                        Please Select One*
                    </div>
                    <div className="name2">
                        <div>
                            <input type="radio" value="Voters" name="radAnswer" onChange={(event) => {this.setState({id: event.target.value})}} />
                            <div>Voter's Id</div>
                        </div>
                        <div>
                            <input type="radio" value="NHIS" name="radAnswer" onChange={(event) => {this.setState({id: event.target.value})}} />
                            <div>NHIS</div>
                        </div>
                        <div>
                            <input type="radio" value="GhanaCard" name="radAnswer" onChange={(event) => {this.setState({id: event.target.value})}} />
                            <div>Ghana Card</div>
                        </div>
                        <div>
                            <input type="radio" value="Passport" name="radAnswer" onChange={(event) => {this.setState({id: event.target.value})}} />
                            <div>Passport</div>
                        </div>
                    </div>
                    {
                        this.state.id ?
                    <div className="dob2">
                        <input type="text"  onChange={(event) => {this.setState({idnum: event.target.value})}} placeholder={this.state.id === "Voters" ? "Enter Voter's ID Number*" : (this.state.id === "NHIS" ? "Enter NHIS ID Number*": (this.state.id === "Passport" ? "Enter Passport ID Number*" : (this.state.id === "GhanaCard" ? "Enter Ghana Card Number*" : ""))) } />
                    </div>:null
                    }
                    <div className="dob2">
                        <input type="text" onChange={(event) => {this.setState({imre: event.target.value})}} placeholder="Immediate Relative*" />
                    </div>
                    <div className="dob2">
                        <input type="number" onChange={(event) => {this.setState({imrp: event.target.value})}} placeholder="Immediate Relative Phone Number*" />
                    </div>
                    <div className="dob2">
                        Choose a package*
                    </div>
                    <div className="dob2">
                        <select onChange={(event) => {this.setState({promo: event.target.value})}}>
                            <option value="" disabled selected>Select Package*</option>
                            <option value="Premium">Premium</option>
                            <option value="Premium Polish Up">Premium Polish Up</option>
                            <option value="Standard">Standard</option>
                            <option value="Standard Polish Up">Standard Polish Up</option>
                        </select>
                    </div>
                    <div className="dob">
                        <input type="number" onChange={(event) => {this.setState({dep: event.target.value})}} placeholder="Deposite*" />
                        <input type="text" readonly="readonly" style={{color: "#999"}} value={"₵" + (this.state.promo === "Premium" ? 2800 - Number(this.state.dep) : (this.state.promo === "Standard" ? 2500 - Number(this.state.dep) : (this.state.promo === "Premium Polish Up" ? 2200 - Number(this.state.dep) : (this.state.promo === "Standard Polish Up" ? 1800 - Number(this.state.dep) : null))))} placeholder="Balance" />
                    </div>
                    
                    <div className="sub">
                        <button disabled={this.state.dis} onClick={(e) => {
                            e.preventDefault()
                            if (this.state.fname && this.state.files && this.state.lname && this.state.dob && this.state.pob && this.state.ra && this.state.email && this.state.branch && this.state.id && this.state.imre && this.state.promo && this.state.dep) {
                                this.setState({dis: true})
                                this.setState({isLoading: true})
                                let bucketName = "images"
                                let file = this.state.files[0]
                                let storageRef = firebase.storage().ref().child("Images/" + `${file.name}`)
                                let uploadTask = storageRef.put(file)
                                console.log(uploadTask)
                                uploadTask.then(() => {
                                    storageRef.getDownloadURL().then((url) => {
                                        this.setState({imageUrl: url})
                                        // console.log(url)
                                        this.setState({ isOn: false });
                                        var ft = firebase.database().ref("Data4534/").push()
                                        var key = ft.key
                                        firebase.database().ref("Data4534/" + key).set({
                                            image: url,
                                            id: key,
                                            fname: this.state.fname,
                                            mname: this.state.mname,
                                            lname: this.state.lname,
                                            dob: this.state.dob,
                                            pob: this.state.pob,
                                            pa: this.state.pa,
                                            ra: this.state.ra,
                                            email: this.state.email,
                                            phone: this.state.phone,
                                            idtype: this.state.id,
                                            idnum: this.state.idnum,
                                            imre: this.state.imre,
                                            imrp: this.state.imrp,
                                            promo: this.state.promo,
                                            dep: this.state.dep,
                                            branch: this.state.branch,
                                            created: Date.now()
                                        }).then(() => {
                                            firebase.database().ref("Notifications/" + key).set({
                                                fname: this.state.fname,
                                                mname: this.state.mname,
                                                lname: this.state.lname,
                                                reason: "Register",
                                                id: key,
                                                created: Date.now()
                                            }).then(() => {
                                                    this.setState({
                                                        polo: true,
                                                        isLoading: false,
                                                        fname: "",
                                                        dis: false,
                                                        mname: "",
                                                        lname: "",
                                                        dob: "",
                                                        pob: "",
                                                        pa: "",
                                                        ra: "",
                                                        email: "",
                                                        phone: "",
                                                        id: "",
                                                        idnum: "",
                                                        imre: "",
                                                        promo: "",
                                                        dep: "",
                                                        pay: "",
                                                        branch: ""
                                                    })
                                            })
                                        })
                                    })
                                }).catch((e) => console.log(e))
                            } else {
                                this.setState({ems: true})
                            }
                        }}>
                            {
                                this.state.isLoading ?
                                <div className="lds-dual-ring"></div>: "Apply"
                            }
                            </button>
                    </div>
                </form>
            </div>
            {
                this.state.polo ?
                <div className="upc">
                    <div className="hope">
                        <div className="h1">Thank you for registering with us.</div>
                        <div className="h2">We will review and reply soon.</div>
                        <Link to="/">Return Home</Link>
                    </div>
                </div>:null
            }
            <footer>
                <div className="hn"></div>
                <div className="footer">
                    <div className="f1">
                        <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "50%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: 150}}></div>
                        <div style={{fontSize: 14, marginTop: 10}}>We have a team of experienced and certified trainers who will help you from the start to end of a driving lesson.</div>
                    </div>
                    <div className="f2">
                        <h1>Links</h1>
                        <div className="nb">
                            <Link to="/About">About</Link>
                            <Link to="/Gallery">Gallery</Link>
                            <Link to="/Constact">Contact</Link>
                        </div>
                    </div>
                    <div className="f1">
                        <h1>Contacts</h1>
                        <div className="ff">
                            <div><FiPhone /></div>
                            <div>Tel.: Ejisu  Branch: 
                            0244991356,
                            0241426886,
                            0208599110
                            <br />
                            Abuakwa Branch:
                            0244991356,
                            0207893398</div>
                        </div>
                        <div className="ff">
                            <div><AiOutlineMail /></div>
                            <div>almightydrivingschool@gmail.com</div>
                        </div>
                    </div>
                    <div className="f1">
                        <h1>Opening Hours</h1>
                        <div style={{marginTop: 10, fontSize: 13, color: "#999"}}>Week Days: 07:30 am - 06:00 pm</div>
                        <div style={{marginTop: 10, fontSize: 13, color: "#999"}}> Saturday: 08:30 am - 03:30 pm</div>
                    </div>
                </div>
                <div style={{width: "100%", textAlign: 'center', marginTop: 20, fontSize: 14}}>&copy; All Rights Reserved. Made by Jackson Osei Kojo 2021.</div>
            </footer>
        </div>
    )
   
}
}

export default Register;