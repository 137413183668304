import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { AiOutlineSafety, AiFillCar, AiOutlineMail} from 'react-icons/ai';
import { BsCalendar} from 'react-icons/bs';
import { IoIosWallet} from 'react-icons/io';
import { VscLocation} from 'react-icons/vsc';
import { FiPhone} from 'react-icons/fi';
import T1 from '../assets/testi/1.jpeg';
import T2 from '../assets/testi/2.jpeg';
import T3 from '../assets/testi/3.jpeg';
import Logo from '../assets/logo.jpg';
import Imp from './imp';

class Home extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            mm: false,
            gf: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
    return (
        <div className="App">
            <header>
                <div className="header">
                    <div className="logo"></div>
                    <div className="nb">
                        <Link to="/">Home</Link>
                        <Link to="/Gallery">Gallery</Link>
                        <Link to="/About">About Us</Link>
                        <Link to="/Contact">Contact</Link>
                        <Link to="/Register" className="bv">Register Now</Link>
                    </div>
                    <div className="menu" onClick={() => this.setState({gf: true})}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </header>
            {
                this.state.gf ?
                    <div className="jkf">
                        <div className="close">
                            <div></div>
                            <div onClick={() => this.setState({gf: false})}>x</div>
                        </div>
                        <div className="nb">
                            <Link to="/">Home</Link>
                            <Link to="/Gallery">Gallery</Link>
                            <Link to="/About">About Us</Link>
                            <Link to="/Contact">Contact</Link>
                            <Link to="/Register" className="bv">Register Now</Link>
                        </div>
                    </div>
                :null
            }
            <section>
                <div className="gh">
                    <div className="gg">
                        <div className="li"></div>
                        <div className="lo">The most advanced driving school in Ghana.</div>
                        <div className="ghd">01</div>
                    </div>
                    <div className="fg">
                        <div>Driving can be intimidating and stressful. But with the expert instruction from Almighty Driving School, you will not have to worry.</div>
                        <Link to="/Register">Register Now</Link>
                    </div>
                </div>
                 <div className="med">
                    <div class='slideshow-image'></div>
                    <div className="lk2"></div>
                    <div className="lk">
                        <div></div>
                    </div>
                </div>
                <div className="pr">
                    <div className="prm">
                        <div className="hd">Standard</div>
                        <div className="hq">
                            <div>Leaner's licence</div>
                            <div>Fees</div>
                            <div>Driving Licence</div>
                            <div>3 months experience</div>
                            <div>₵2500</div>
                        </div>
                        <div className="link"><Link to="/Register">Register Now</Link></div>
                    </div>
                    <div className="prm">
                        <div className="hd">Premium</div>
                        <div className="hq">
                            <div>Leaner's licence</div>
                            <div>Fees</div>
                            <div>Driving Licence</div>
                            <div>1 month experience</div>
                            <div>₵3000</div>
                        </div>
                        <div className="link"><Link to="/Register">Register Now</Link></div>
                    </div>
                </div>
            </section>
            <div className="kj">
                <div className="k1"></div>    
                <div className="k2">
                    <div className="h1"><span style={{color: "rgb(72, 255, 0)"}}>Ready For </span><span>A Safe, Fun Driving Experience?</span></div>
                    <div className="h2">The school offers the following services for first-time drivers, learners Licence and assist you get your drivers licence from DVLA after completion.</div>
                    <div className="jj"><div></div></div>
                    <div className="as">
                        <div className="lk">
                            <div><AiOutlineSafety size="40" color="rgb(72, 255, 0)" /></div>
                            <div className="kk">
                                <div style={{fontSize: 20}}>Best Safety Measures</div>
                                <div style={{marginTop: 10, fontSize: 13, color: "#999"}}>Our Instructors are Highly Trained in the latest Safety</div>
                            </div>
                        </div>
                        <div className="lk">
                            <div><AiFillCar size="40" color="rgb(72, 255, 0)" /></div>
                            <div className="kk">
                                <div style={{fontSize: 20}}>Class Formats</div>
                                <div style={{marginTop: 10, fontSize: 13, color: "#999"}}>We offer In Classroom Drivers Education</div>
                            </div>
                        </div>
                        <div className="lk">
                            <div><BsCalendar size="40" color="rgb(72, 255, 0)" /></div>
                            <div className="kk">
                                <div style={{fontSize: 20}}>Perfect Timing</div>
                                <div style={{marginTop: 10, fontSize: 13, color: "#999"}}>Now is the perfect time to start your In Class</div>
                            </div>
                        </div>
                        <div className="lk">
                            <div><IoIosWallet size="40" color="rgb(72, 255, 0)" /></div>
                            <div className="kk">
                                <div style={{fontSize: 20}}>Affordable Fee</div>
                                <div style={{marginTop: 10, fontSize: 13, color: "#999"}}>We know this process can be expensive</div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
            <div className="fd">
                <div className="de"><span>What </span><span style={{color: "rgb(72, 255, 0)"}}>Client Says </span>About Us</div>
                <div className="qw">
                    <div></div>
                </div>
                <div className="ge">
                    <div className="yy">
                        <div className="im" style={{backgroundImage: `url(${T1})`, backgroundSize: "cover", backgroundPosition: "center"}}></div>
                        <div style={{marginTop: 100, fontSize: 30}}>''</div>
                        <div className="dj" style={{fontSize: 13, lineHeight: 1.5}}>
                        I would highly recommend Almighty Driving School. They have friendly, respectful and professional Instructors who will guide you during your theoretical and practical lessons. The customer service provided by the administrative staff was definitely 5 star. 
Almighty Driving School is best place to polish your driving skills and your confidence.
                        </div>
                        <div style={{marginTop: 20, marginBottom: 20, fontSize: 20, color: "rgb(72, 255, 0)"}}>Mr. Oduro Mensah</div>
                    </div>
                    <div className="yy">
                        <div className="im" style={{backgroundImage: `url(${T2})`, backgroundSize: "cover", backgroundPosition: "center"}}></div>
                        <div style={{marginTop: 100, fontSize: 30}}>''</div>
                        <div className="dj" style={{fontSize: 13, lineHeight: 1.5}}>
                        Almighty driving school is a responsible driving school with credibility. They are equipped with preferssional  and qualified teachers who will make sure  you are equipped with the right driving skills so far as driving is concerned. I was once a student there  and have not regretted being enrolled there and  I personally recommend it to my friends who wanted to get driving license and still will recommend for  every individual desire to become  a preferssional driver  Almighty driving school have two branch in kumasi ,one  is at Abuakwa Nkawie road and other one at Ejisu. 
                        </div>
                        <div style={{marginTop: 20, marginBottom: 20, fontSize: 20, color: "rgb(72, 255, 0)"}}>Mrs. Lamisi Vida</div>
                    </div>
                    <div className="yy">
                        <div className="im" style={{backgroundImage: `url(${T3})`, backgroundSize: "cover", backgroundPosition: "center"}}></div>
                        <div style={{marginTop: 100, fontSize: 30}}>''</div>
                        <div className="dj" style={{fontSize: 13, lineHeight: 1.5}}>
                        “Almighty driving school is one that I would highly recommend. Whether you’re new to driving or a bit exposed, they have experienced teachers who can train you adequately. Their customer service and man management is top notch and they seek to leave a mark on all those they work with”
                        </div>
                        <div style={{marginTop: 20, marginBottom: 20, fontSize: 20, color: "rgb(72, 255, 0)"}}>Prince Osei Marfo</div>
                    </div>
                </div>
            </div>
            <div className="qq">
                <h1>Photo Gallery</h1>
                <div>Check out photos of our schools, cars and students.</div>
                <div className="ph">
                    <Imp />
                </div>
            </div>
            <footer>
                <div className="hn"></div>
                <div className="footer">
                    <div className="f1">
                        <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "50%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: 150}}></div>
                        <div style={{fontSize: 14, marginTop: 10}}>We have a team of experienced and certified trainers who will help you from the start to end of a driving lesson.</div>
                    </div>
                    <div className="f2">
                        <h1>Links</h1>
                        <div className="nb">
                            <Link to="/About">About</Link>
                            <Link to="/Gallery">Gallery</Link>
                            <Link to="/Constact">Contact</Link>
                        </div>
                    </div>
                    <div className="f1">
                        <h1>Contacts</h1>
                        <div className="ff">
                            <div><FiPhone /></div>
                            <div>Tel.: Ejisu  Branch: 
                            0244991356,
                            0241426886,
                            0208599110
                            <br />
                            Abuakwa Branch:
                            0244991356,
                            0207893398</div>
                        </div>
                        <div className="ff">
                            <div><AiOutlineMail /></div>
                            <div>almightydrivingschool@gmail.com</div>
                        </div>
                    </div>
                    <div className="f1">
                        <h1>Opening Hours</h1>
                        <div style={{marginTop: 10, fontSize: 13, color: "#999"}}>Week Days: 07:30 am - 06:00 pm</div>
                        <div style={{marginTop: 10, fontSize: 13, color: "#999"}}> Saturday: 08:30 am - 03:30 pm</div>
                    </div>
                </div>
                <div style={{width: "100%", textAlign: 'center', marginTop: 20, fontSize: 14}}>&copy; All Rights Reserved. Made by Jackson Osei Kojo 2021.</div>
            </footer>
        </div>
    )
   
}
}

export default Home;