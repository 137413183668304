import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import {  AiOutlineMail} from 'react-icons/ai';
import Logo from '../assets/logo.jpg';
import { FiPhone} from 'react-icons/fi';
import Gallery from 'react-photo-gallery';
import Imp2 from './imp2';

class Galleri extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            mm: false,
            gf:false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
    return (
        <div className="App">
            <header>
                <div className="header">
                    <div className="logo"></div>
                    <div className="nb">
                        <Link to="/">Home</Link>
                        <Link to="/Gallery">Gallery</Link>
                        <Link to="/About">About Us</Link>
                        <Link to="/Contact">Contact</Link>
                        <Link to="/Register" className="bv">Register Now</Link>
                    </div>
                    <div className="menu" onClick={() => this.setState({gf: true})}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </header>
            {
                this.state.gf ?
                    <div className="jkf">
                        <div className="close">
                            <div></div>
                            <div onClick={() => this.setState({gf: false})}>x</div>
                        </div>
                        <div className="nb">
                            <Link to="/">Home</Link>
                            <Link to="/Gallery">Gallery</Link>
                            <Link to="/About">About Us</Link>
                            <Link to="/Contact">Contact</Link>
                            <Link to="/Register" className="bv">Register Now</Link>
                        </div>
                    </div>
                :null
            }
            <div style={{width: "90%", marginTop: 20, marginLeft: "auto", marginRight: "auto"}}>
                <h1 style={{fontSize: 50}}>Gallery</h1>
                <Imp2 />
            </div>
            <footer>
                <div className="hn"></div>
                <div className="footer">
                    <div className="f1">
                        <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "50%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%", height: 150}}></div>
                        <div style={{fontSize: 14, marginTop: 10}}>We have a team of experienced and certified trainers who will help you from the start to end of a driving lesson.</div>
                    </div>
                    <div className="f2">
                        <h1>Links</h1>
                        <div className="nb">
                            <Link to="/About">About</Link>
                            <Link to="/Gallery">Gallery</Link>
                            <Link to="/Constact">Contact</Link>
                        </div>
                    </div>
                    <div className="f1">
                        <h1>Contacts</h1>
                        <div className="ff">
                            <div><FiPhone /></div>
                            <div>Tel.: Ejisu  Branch: 
                            0244991356,
                            0241426886,
                            0208599110
                            <br />
                            Abuakwa Branch:
                            0244991356,
                            0207893398</div>
                        </div>
                        <div className="ff">
                            <div><AiOutlineMail /></div>
                            <div>almightydrivingschool@gmail.com</div>
                        </div>
                    </div>
                    <div className="f1">
                        <h1>Opening Hours</h1>
                        <div style={{marginTop: 10, fontSize: 13, color: "#999"}}>Week Days: 07:30 am - 06:00 pm</div>
                        <div style={{marginTop: 10, fontSize: 13, color: "#999"}}> Saturday: 08:30 am - 03:30 pm</div>
                    </div>
                </div>
                <div style={{width: "100%", textAlign: 'center', marginTop: 20, fontSize: 14}}>&copy; All Rights Reserved. Made by Jackson Osei Kojo 2021.</div>
            </footer>
        </div>
    )
   
}
}

export default Galleri;