export const photos = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Gallery%2F77.jpg?alt=media&token=89ef693d-5ea0-42c4-92df-d5764f3b3786",
      width: 4,
      height: 3
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Gallery%2F14.jpg?alt=media&token=13b68ced-8ccd-4438-a48a-b2885808c89d",
      width: 4,
      height: 3
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Gallery%2F17.jpg?alt=media&token=33caf1a8-42f0-42d9-9f53-0f67fe6f0cb0",
      width: 4,
      height: 3
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Gallery%2F2.jpg?alt=media&token=41ec3c33-7234-4bc8-93e0-5be8857227b2",
      width: 4,
      height: 3
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Gallery%2F3.jpg?alt=media&token=fc854b40-44d5-46fc-9b01-39a9c4534529",
      width: 4,
      height: 3
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Gallery%2F1.jpg?alt=media&token=e1dc12ba-5b6b-442b-bd76-bc4e4b327266",
      width: 4,
      height: 3
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Gallery%2F42.jpg?alt=media&token=2cc0fe33-6ef2-4d65-bbdf-965e830800a7",
      width: 4,
      height: 3
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Gallery%2F46.jpg?alt=media&token=00e161c2-e3f2-4012-9421-7cd837070e74",
      width: 4,
      height: 3
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Gallery%2F60.jpg?alt=media&token=94d36da6-0fb4-4593-a2ca-07709fc926d0",
      width: 4,
      height: 3
    }
  ];
  