import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { GiMoneyStack} from 'react-icons/gi';
import { VscLinkExternal} from 'react-icons/vsc';
import { BsCalendar} from 'react-icons/bs';
import { BiUserPlus} from 'react-icons/bi';
import Background from '../assets/1.JPG';
import moment from 'moment';
import firebase from 'firebase';
import Logo from '../assets/logo.jpg'

class Student extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            gf: false,
            dat: [],
            name: "",
            hold: "",
            search: "",
            branch: "",
            filter: "",
        };
    }
    componentDidMount() {
        this._isMounted = true;
        
        firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid).on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                this.setState({name: data.firstname + " " + data.lastname, hold: data.hold, img: data.img, branch: data.branch})
              }
            }
          })
          
        firebase.database().ref("Data4534/").on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                let dat = Object.values(data);
                this.setState({dat})
              }
            }
          })
          this.setState({filter: this.props.location.state ? this.props.location.state.date : "", search: this.props.location.state ? this.props.location.state.branch : ""})
        // console.log(this.props.location.state.date)
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
    const {search, filter, branch} = this.state
    return (
        <div className="admin">
            
            <header>
               <div className="header">
                    <div className="menu" onClick={() => this.setState({gf: true})}>
                        <div></div>
                        <div></div>
                        <div className="lit"></div>
                    </div>
                    <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "65%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "10%", height: 100}}></div>
                    <div className="prof">
                        <div></div>
                        <div className="p1">
                            <div>{this.state.name}</div>
                            <div style={{fontSize: 12, color: "#999"}}>{this.state.hold}</div>
                            <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} style={{fontSize: 12, color: "#f00", cursor: "pointer"}}>Logout</div>
                        </div>
                        <div className="p2" style={{backgroundImage: `url(${this.state.img})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                            
                        </div>
                    </div>
               </div>
            </header>
            <section>
                
            {
                this.state.hold === "Manager" || this.state.hold === "Developer" ?
                <div className="stud">
                    <div className="sm">
                        <div>Students({this.state.dat.filter(function (item) {
                            const itemData = (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo + " " + item.branch + " " + item.phone) ? (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo + " " + item.branch + " " + item.phone).toUpperCase() : ''.toUpperCase();
                            const textData = search.toUpperCase();
                            return (
                            item.active && (item.comp !== "Completed") && (filter ? moment(new Date(item.created)).format('YYYY-MM') === filter : item.created) ? itemData.indexOf(textData) > -1 : "")}).length})</div>
                            <div><input type="month" className="select" onChange={(event) => {this.setState({filter: event.target.value})}} min="2021-01" value={this.state.filter} /></div>
                        <div><input type="search" value={search} onChange={(e) => this.setState({search: e.target.value})} placeholder="Search..." /></div>
                    </div>
                    <div className="hggh">
                        <div style={{width: "2%", }}>No</div>
                        <div style={{width: "35%", }}>Name</div>
                        <div style={{width: "7%", }}>Amount</div>
                        <div style={{width: "7%", }}>Dept</div>
                        <div style={{width: "12%", }}>Branch</div>
                        <div style={{width: "15%", }}>Package</div>
                        <div style={{width: "20%", }}>Date</div>
                    </div>
                    <div className="oi">
                        
                        {
                            this.state.dat.filter(function (item) {
                                const itemData = (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo + " " + item.branch + " " + item.phone) ? (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo + " " + item.branch + " " + item.phone).toUpperCase() : ''.toUpperCase();
                                const textData = search.toUpperCase();
                                return (
                                    (item.active) && ((item.comp !== "Completed") && (filter ? moment(new Date(item.created)).format('YYYY-MM') === filter : item.created) ? itemData.indexOf(textData) > -1 : "")
                                )
                            }).sort((a, b) => new Date(a) < new Date(b) ? 1 : -1).map((item, index) => {
                                return (
                                    <Link key={item.id} to={{
                                        pathname:'/StudentDetails',
                                        state: {name:`${item.id}`}  
                                      }} className="gh2">
                                        <div style={{width: "2%", }}>{index + 1}.</div>
                                        <div style={{width: "35%", }}>{item.fname + " " + item.mname + " " + item.lname}</div>
                                        <div style={{width: "7%", }}>₵{item.dep}</div>
                                        <div style={{width: "9%", }}>₵{item.promo === "Premium" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) : 
                                        (item.promo === "Standard" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) : 
                                        (item.promo === "Premium Polish Up" ? (moment(new Date(item.created)).format('YYYY') === "2021" || moment(new Date(item.created)).format('YYYY-MM') === "2022-01" ? 1800 : 2200) - Number(item.dep) : 
                                        (item.promo === "Standard Polish Up" ? (moment(new Date(item.created)).format('YYYY') === "2021" || moment(new Date(item.created)).format('YYYY-MM') === "2022-01" ? 1500 : 1800) - Number(item.dep) : 
                                        (item.promo === "Standard Licence" ? (moment(new Date(item.created)).format('YYYY') === "2021" || moment(new Date(item.created)).format('YYYY-MM') === "2022-01" ? 1000 : 1200) - Number(item.dep) : 
                                        (item.promo === "Premium Licence" ? 1500 - Number(item.dep) : 
                                        (item.promo === "Practicals Only" ? (moment(new Date(item.created)).format('YYYY') === "2021" || moment(new Date(item.created)).format('YYYY-MM') === "2022-01" ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null))))))}</div>
                                        <div style={{width: "12%", }}>{item.branch}</div>
                                        <div style={{width: "15%", }}>{item.promo}</div>
                                        <div style={{width: "20%", }}>{moment(new Date(item.created)).format("MMM Do YY, h:mm:ss a")}</div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>:
                <div className="stud">
                <div className="sm">
                    <div>Students({this.state.dat.filter(function (item) {return (item.active && (item.comp !== "Completed") && (filter ? moment(new Date(item.created)).format('YYYY-MM') === filter : item.created) && (item.branch ? (item.branch === branch) : true))}).length})</div>
                    <div><input type="month" className="select" onChange={(event) => {this.setState({filter: event.target.value})}} min="2021-01" value={this.state.filter} /></div>
                    <div><input type="search" onChange={(e) => this.setState({search: e.target.value})} placeholder="Search..." /></div>
                </div>
                <div className="hggh">
                    <div style={{width: "2%", }}>No</div>
                    <div style={{width: "35%", }}>Name</div>
                    <div style={{width: "7%", }}>Amount</div>
                    <div style={{width: "7%", }}>Dept</div>
                    <div style={{width: "12%", }}>Branch</div>
                    <div style={{width: "15%", }}>Package</div>
                    <div style={{width: "20%", }}>Date</div>
                </div>
                <div className="oi">
                    
                    {
                        this.state.dat.filter(function (item) {
                            const itemData = (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo + " " + item.phone) ? (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo + " " + item.phone).toUpperCase() : ''.toUpperCase();
                            const textData = search.toUpperCase();
                            return (
                                (item.active) && ((item.comp !== "Completed") && (filter ? moment(new Date(item.created)).format('YYYY-MM') === filter : item.created) ? itemData.indexOf(textData) > -1 : "") && (item.branch ? (item.branch === branch) : true)
                            )
                        }).sort((a, b) => new Date(a) < new Date(b) ? 1 : -1).map((item, index) => {
                            return (
                                <Link key={item.id} to={{
                                    pathname:'/StudentDetails',
                                    state: {name:`${item.id}`}  
                                  }} className="gh2">
                                    <div style={{width: "2%", }}>{index + 1}.</div>
                                    <div style={{width: "35%", }}>{item.fname + " " + item.mname + " " + item.lname}</div>
                                    <div style={{width: "7%", }}>₵{item.dep}</div>
                                    <div style={{width: "9%", }}>₵{item.promo === "Premium" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) : 
                                        (item.promo === "Standard" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) : 
                                        (item.promo === "Premium Polish Up" ? (moment(new Date(item.created)).format('YYYY') === "2021" || moment(new Date(item.created)).format('YYYY-MM') === "2022-01" ? 1800 : 2200) - Number(item.dep) : 
                                        (item.promo === "Standard Polish Up" ? (moment(new Date(item.created)).format('YYYY') === "2021" || moment(new Date(item.created)).format('YYYY-MM') === "2022-01" ? 1500 : 1800) - Number(item.dep) : 
                                        (item.promo === "Standard Licence" ? (moment(new Date(item.created)).format('YYYY') === "2021" || moment(new Date(item.created)).format('YYYY-MM') === "2022-01" ? 1000 : 1200) - Number(item.dep) : 
                                        (item.promo === "Premium Licence" ? 1500 - Number(item.dep) : 
                                        (item.promo === "Practicals Only" ? (moment(new Date(item.created)).format('YYYY') === "2021" || moment(new Date(item.created)).format('YYYY-MM') === "2022-01" ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null))))))}</div>
                                    <div style={{width: "12%", }}>{item.branch}</div>
                                    <div style={{width: "15%", }}>{item.promo}</div>
                                    <div style={{width: "20%", }}>{moment(new Date(item.created)).format("MMM Do YY, h:mm:ss a")}</div>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>}
            </section>
            {
                this.state.gf ?
                <div className="mr">
                    <div className="close">
                        <div></div>
                        <div onClick={() => this.setState({gf: false})}>x</div>
                    </div>
                    <div className="kij">
                        <Link to="/Admin1496"><span>Home</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Analytics"><span>Analytics</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Students"><span>Update Fee</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsOwing"><span>Students Owing</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsCompleted"><span>Completed Students</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/ChangePassword"><span>Change Password</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} className="lo" style={{marginTop: 30}}><span>Logout</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></div></div>
                </div>:null
            }
        </div>
    )
   
}
}

export default Student;