import React, {useState} from "react";
import ReactDOM from "react-dom";
import ReactToPdf from "react-to-pdf";
import Logo from '../assets/logo.jpg';
import moment from 'moment';
import firebase from 'firebase';


const ref = React.createRef();
var min = 1;
var max = 10000000000;
var rand =  min + (Math.random() * (max-min));
const fname = `Receipt${rand}.pdf`
const options = {
    orientation: 'portrait',
    unit: 'in',
    // format: [4,2]
};

function Cliff(props) {
    const [users, setUser] = useState(false);
    const [dat, setDat] = useState([]);
    const [name, setName] = useState("");
    const [namee, setNamee] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [promo, setPromo] = useState("");
    const [email, setEmail] = useState("");

    React.useEffect(() => {
        firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid).on("value", snapshot => {
            
              if (snapshot.exists()) {
                let data = snapshot.val()
                setName(data.firstname + " " + data.lastname)
              }
            
          })
          
        firebase.database().ref("Data4534/" + props.location.state.id).on("value", snapshot => {
            
              if (snapshot.exists()) {
                let data = snapshot.val()
                setNamee(data.fname + (data.mname ? " " + data.mname + " " : " ") + data.lname)
                setEmail(data.email)
                setPhone(data.phone)
                setAddress(data.pa)
                setCity(data.branch)
                setPromo(data.promo)
              }
            
          })
          
        firebase.database().ref("Data4534/" + props.location.state.id + "/depid/").on("value", snapshot => {
            
              if (snapshot.exists()) {
                let data = snapshot.val()
                let datp = Object.values(data);
                setDat(datp)
              }
            
          })
      }, []);
    return (
        <div className="Appy">
            <div className="receipt" ref={ref}>
                {/* <div className="toph"> */}
                    <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "65%", margin: "auto", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "20%", height: 150}}></div>
                {/* </div> */}
                <h1>ALMIGHTY DRIVING SCHOOL</h1>
                <div className="hgf">
                    <div>Statement Date: {moment(Date.now()).format('DD/MM/YYYY')}</div>
                    <div>Receipt No: {rand.toFixed(0)}</div>
                </div>
                <div className="bnm">
                    <div>
                        <div>{namee}</div>
                        <div>{address}</div>
                        <div>{city}</div>
                    </div>
                    <div>
                        <div>{phone}</div>
                        <div>{email}</div>
                    </div>
                </div>
                <div className="azx">
                    <table>
                        <tr>
                            <th>Date</th>
                            <th>Subscription</th>
                            <th>Payment</th>
                            <th>Balance</th>
                        </tr>
                        {
                            dat.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{moment(item.created).format('DD/MM/YYYY')}</td>
                                        <td>{promo}</td>
                                        <td>{item.depi}</td>
                                        <td>{item.dep}</td>
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td className="xcq">Total</td>
                            <td className="xcq">₵{dat.reduce((total, currentValue) => total = total + Number(currentValue.depi), 0)}</td>
                        </tr>
                    </table>
                </div>
            </div>
            {/* <Pdf targetRef={ref} filename={fname}>
                {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
            </Pdf> */}
            <ReactToPdf targetRef={ref} filename="div-blue.pdf" options={options} x={.5} y={.5} scale={0.8}>
        {({toPdf}) => (
            <button onClick={toPdf} style={{marginTop: 50, border: "none", padding: 10, fontWeight: "bold",}}>Print</button>
        )}
    </ReactToPdf>
        </div>
    );
}

export default Cliff;
