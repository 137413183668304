import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { VscLinkExternal} from 'react-icons/vsc';
import Background from '../assets/1.JPG';
import moment from 'moment';
import Logo from '../assets/logo.jpg'
import firebase from 'firebase';

class Pform extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            gf: false,
            datp: [],
            img: "",
            name: "",
            hold: "",
            ed: false,
            hr: 0,
            prog: "",
            start: "",
            repeat: "",
            kj: false
        };
    }
    componentDidMount() {
        this._isMounted = true;
        
        firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid).on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                this.setState({name: data.firstname + " " + data.lastname, hold: data.hold, img: data.img})
              }
            }
          })
          
        firebase.database().ref("Data4534/" + this.props.location.state.id + "/Pform/").on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                let datp = Object.values(data);
                this.setState({datp})
              }
            }
          })
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
    const {search, filter} = this.state
    return (
        <div className="admin">
            <header>
               <div className="header">
                    <div className="menu" onClick={() => this.setState({gf: true})}>
                        <div></div>
                        <div></div>
                        <div className="lit"></div>
                    </div>
                    <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "65%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "10%", height: 100}}></div>
                    <div className="prof">
                        <div></div>
                        <div className="p1">
                            <div>{this.state.name}</div>
                            <div style={{fontSize: 12, color: "#999"}}>{this.state.hold}</div>
                            <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} style={{fontSize: 12, color: "#f00", cursor: "pointer"}}>Logout</div>
                        </div>
                        <div className="p2" style={{backgroundImage: `url(${this.state.img})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                            
                        </div>
                    </div>
               </div>
            </header>
            <section>
                <div className="stud">
                    <div className="sm">
                        <div>Progressive Form</div>
                    </div>
                    
                    <div className="hggh">
                        <div style={{width: "9%", }}>Hrs</div>
                        <div style={{width: "65%", }}>PROGRESS</div>
                        <div style={{width: "9%", }}>START</div>
                        <div style={{width: "9%", }}>REPEAT</div>
                    </div>
                    <div className="oi">
                    {
                        this.state.datp.map(item => {
                            return (
                                <div key={item.id} className="gh2">
                                    <div style={{width: "9%", }}>{item.hr}</div>
                                    <div style={{width: "65%", }}>{item.prog}</div>
                                    <div style={{width: "9%", }}>{item.start}</div>
                                    <div style={{width: "9%", }}>{item.repeat}</div>
                                </div>
                            )
                        })
                    }
                        {
                            this.state.ed ?
                        <div className="gh2">
                            <div style={{width: "9%", }}><input type='number' onChange={(e) => this.setState({ hr: e.target.value})} style={{width: "100%", paddingTop: 10, paddingBottom: 10, textIndent: 10, fontSize: 16}} /></div>
                            <div style={{width: "65%", }}><input type='text' onChange={(e) => this.setState({ prog: e.target.value})} style={{width: "100%", paddingTop: 10, paddingBottom: 10, textIndent: 10, fontSize: 16}} /></div>
                            <div style={{width: "9%", }}><input type='text' onChange={(e) => this.setState({ start: e.target.value})} style={{width: "100%", paddingTop: 10, paddingBottom: 10, textIndent: 10, fontSize: 16}} /></div>
                            <div style={{width: "9%", }}><input type='text' onChange={(e) => this.setState({ repeat: e.target.value})} style={{width: "100%", paddingTop: 10, paddingBottom: 10, textIndent: 10, fontSize: 16}} /></div>
                        </div>:null
                        }
                        <div className="hggh" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <button onClick={(e) => {
                                e.preventDefault();
                                if (this.state.ed === false) {
                                    this.setState({ed: true})
                                } else if (this.state.hr && this.state.prog && this.state.start && this.state.repeat && this.state.ed === true) {
                                    var ft = firebase.database().ref("Data4534/" + this.props.location.state.id + "/depid/").push()
                                    var key = ft.key
                                    firebase.database().ref("Data4534/" + this.props.location.state.id + "/Pform/" + key).set({
                                        student: this.props.location.state.id,
                                        key,
                                        hr: this.state.hr,
                                        prog: this.state.prog,
                                        start: this.state.start,
                                        repeat: this.state.repeat,
                                        created: Date.now(),
                                    }).then(() => {
                                        this.setState({ed: false})
                                    })
                                }
                            }} style={{borderWidth: 0, padding: 10, fontWeight: "bold"}}>Add+</button>
                            {
                                this.state.ed ?
                            <button onClick={(e) => {
                                e.preventDefault();
                                if (this.state.ed === true) {
                                    this.setState({ed: false})
                                } 
                            }} style={{borderWidth: 0, padding: 10, fontWeight: "bold"}}>CloseX</button>:null
                            }
                        </div>
                    </div>
                </div>
            </section>
            {
                this.state.gf ?
                <div className="mr">
                    <div className="close">
                        <div></div>
                        <div onClick={() => this.setState({gf: false})}>x</div>
                    </div>
                    <div className="kij">
                        <Link to="/Admin1496"><span>Home</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Analytics"><span>Analytics</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Students"><span>Update Fee</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsOwing"><span>Students Owing</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsCompleted"><span>Completed Students</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/ChangePassword"><span>Change Password</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} className="lo" style={{marginTop: 30}}><span>Logout</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></div></div>
                </div>:null
            }
        </div>
    )
   
}
}

export default Pform;