import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { GiMoneyStack, GiTreeBranch} from 'react-icons/gi';
import { VscLinkExternal} from 'react-icons/vsc';
import { BsCalendar} from 'react-icons/bs';
import { BiUserPlus} from 'react-icons/bi';
import Background from '../assets/1.JPG';
import moment from 'moment';
import firebase from 'firebase';
import Logo from '../assets/logo.jpg'
import * as emailjs from "emailjs-com";

class Analytics extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            mm: false,
            gf: false,
            dat: [],
            dat2: [],
            name: "",
            hold: "",
            search: "",
            filter: "",
            img: "", 
            branch: "",
            isLoading: false,
            isl: false,
        };
    }
    componentDidMount() {
        // firebase.auth().signOut()
        this._isMounted = true;
        var kk = firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid + "/Logs/").push()
        var key = kk.key;
        firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid + "/Logs/" + key).set({
            key: key,
            created: Date.now()
        })
        
        firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid).on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                this.setState({name: data.firstname + " " + data.lastname, hold: data.hold, img: data.img, branch: data.branch})
              }
            }
          })
          firebase.database().ref("Data4534/").on("value", snapshot => {
              if (this._isMounted) {
                if (snapshot.exists()) {
                  let data = snapshot.val()
                  let dat = Object.values(data);
                  this.setState({dat})
                //   console.log(dat2.filter(function (item) {return (item.depid.filter(function (item) {return (moment(item.created).format('YYYY-MM-DD') === "2022-01-11")}))}).length)
                }
              }
            })
          
        firebase.database().ref("Records/").on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                let dat2 = Object.values(data);
                this.setState({dat2})
                // console.log(dat2)
              }
            }
          })
          this.setState({filter: moment().format('YYYY-MM-DD')})
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
      const {search, filter, branch} = this.state
    return (
        <div className="admin">
            <header>
               <div className="header">
                    <div className="menu" onClick={() => this.setState({gf: true})}>
                        <div></div>
                        <div></div>
                        <div className="lit"></div>
                    </div>
                    <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "65%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "10%", height: 100}}></div>
                    <div className="prof">
                        <div></div>
                        <div className="p1">
                            <div>{this.state.name}</div>
                            <div style={{fontSize: 12, color: "#999"}}>{this.state.hold}</div>
                            <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} style={{fontSize: 12, color: "#f00", cursor: "pointer"}}>Logout</div>
                        </div>
                        <div className="p2" style={{backgroundImage: `url(${this.state.img})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                            
                        </div>
                    </div>
               </div>
            </header>
            <section>
                <div className="section">
                    <div className="sqw">
                        {
                            this.state.hold === "Manager" || this.state.hold === "Developer" ?
                            <div className="he0">
                                <input type="date" className="select" value={this.state.filter} onChange={(event) => {this.setState({filter: event.target.value})}} min="2021-01-01"
                                //  onChange={(event) => {this.setState({filter: event.target.value})}} min="2021-01" value={this.state.filter} 

                                 />
                            </div>: null }
                        {
                            this.state.hold === "Manager" || this.state.hold === "Developer" ?
                            <div className="hed">
                                <div className="l1" style={{marginTop: 10}}>
                                    <div className="he">
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                            <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                            <span>Ejisu</span>
                                        </div>
                                    </div>
                                    <div style={{marginTop: 20, }}>
                                        <div>
                                            <div style={{fontSize: 12, }}>Total Number Of Students:</div>
                                            <div style={{marginTop: 5, color: "#999" }}>{this.state.dat2.filter(function (item) {return (item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM-DD') === filter)}).length}</div>
                                        </div>
                                        <div style={{marginTop: 10}}>
                                            <div style={{fontSize: 12, }}>Total Amount Recieved:</div>
                                            <div style={{marginTop: 5, color: "#999" }}>₵{this.state.dat2.filter(function (item) {return (item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM-DD') === filter)}).reduce((a, b) => a + Number(b.depi), 0)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="l1" style={{marginTop: 10}}>
                                    <div className="he">
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                            <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                            <span>Abuakwa</span>
                                        </div>
                                    </div>
                                    <div style={{marginTop: 20, }}>
                                        <div>
                                            <div style={{fontSize: 12, }}>Total Number Of Students:</div>
                                            <div style={{marginTop: 5, color: "#999" }}>{this.state.dat2.filter(function (item) {return (item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM-DD') === filter)}).length}</div>
                                        </div>
                                        <div style={{marginTop: 10}}>
                                            <div style={{fontSize: 12, }}>Total Amount Recieved:</div>
                                            <div style={{marginTop: 5, color: "#999" }}>₵{this.state.dat2.filter(function (item) {return (item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM-DD') === filter)}).reduce((a, b) => a + Number(b.depi), 0)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :null
                        }
                        <div className="studj">
                            <div className="sm">
                                <div>Students({this.state.dat2.filter(function (item) {
                                    const itemData = (item.name + " " + item.branch) ? (item.name + " " + item.branch).toUpperCase() : ''.toUpperCase();
                                        const textData = search.toUpperCase();
                                        return (
                                            (filter ? moment(new Date(item.created)).format('YYYY-MM-DD') === filter : item.created) ? itemData.indexOf(textData) > -1 : ""
                                        )}).length})</div>
                                </div>
                            <div className="hggh">
                                <div style={{width: "2%", }}>No</div>
                                <div style={{width: "35%", }}>Name</div>
                                <div style={{width: "7%", }}>Amount</div>
                                <div style={{width: "7%", }}>Dept</div>
                                <div style={{width: "12%", }}>Branch</div>
                                <div style={{width: "15%", }}>Package</div>
                                <div style={{width: "20%", }}>Date</div>
                            </div>
                            <div className="oi">
                                
                                {
                                    this.state.dat2.filter(function (item) {
                                        const itemData = (item.name + " " + item.branch) ? (item.name + " " + item.branch).toUpperCase() : ''.toUpperCase();
                                        const textData = search.toUpperCase();
                                        return (
                                            (filter ? moment(new Date(item.created)).format('YYYY-MM-DD') === filter : item.created) ? itemData.indexOf(textData) > -1 : ""
                                        )
                                    }).sort((a, b) => new Date(a) < new Date(b) ? 1 : -1).map((item, index) => {
                                        return (
                                            <Link key={item.id} to={{
                                                pathname:'/StudentDetails',
                                                state: {name:`${item.student}`}  
                                            }} className="gh2">
                                                <div style={{width: "2%", }}>{index + 1}.</div>
                                                <div style={{width: "35%", }}>{item.name}</div>
                                                <div style={{width: "7%", }}>₵{Number(item.depi)}</div>
                                                <div style={{width: "9%", }}>₵{item.dep}</div>
                                                <div style={{width: "12%", }}>{item.branch}</div>
                                                <div style={{width: "15%", }}>{item.promo}</div>
                                                <div style={{width: "20%", }}>{moment(new Date(item.created)).format("MMM Do YY, h:mm:ss a")}</div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                this.state.gf ?
                <div className="mr">
                    <div className="close">
                        <div></div>
                        <div onClick={() => this.setState({gf: false})}>x</div>
                    </div>
                    <div className="kij">
                        <Link to="/Admin1496"><span>Home</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Analytics"><span>Analytics</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Students"><span>Update Fee</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsOwing"><span>Students Owing</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsCompleted"><span>Completed Students</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/ChangePassword"><span>Change Password</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} className="lo" style={{marginTop: 30}}><span>Logout</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></div></div>
                </div>:null
            }
        </div>
    )
   
}
}

export default Analytics;