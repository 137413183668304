import React from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import { GiMoneyStack} from 'react-icons/gi';
import { VscLinkExternal} from 'react-icons/vsc';
import { BsCalendar} from 'react-icons/bs';
import { BiUserPlus} from 'react-icons/bi';
import firebase from 'firebase';

class Login extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            mm: false,
            gf: false,
            email: "",
            password: "",
            ec: "",
            ec2: "",
            isLoading: false,
            emsg: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        // firebase.auth()
        // .createUserWithEmailAndPassword("angelicanimo95@gmail.com", "AngeLica2!")
        // .then(() => {
        //     firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid).set({
        //         email: "almightydrivingschool@gmail.com",
        //         branch: "Abuakwa",
        //         firstname: "Almighty",
        //         hold: "Secretary",
        //         id: firebase.auth().currentUser.uid,
        //         img: "https://firebasestorage.googleapis.com/v0/b/almightydb-9bb51.appspot.com/o/Burner%2FFB_IMG_1613142644914.jpg?alt=media&token=9d385e99-b14e-44a5-97da-f55b6091deb2",
        //         lastname: "Driving School",
        //         created: Date.now(),
        //         password: "Almighty1234",
        //     })
        // })
        
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
    return (
        <div className="admin">
            <div className="log">
                <div className="in">
                    <div className="lo"></div>
                    <div className="co">
                        {
                            this.state.emsg ?
                            <div style={{fontSize: 10, color: "#f00", width: "100%", textAlign: "left"}}>{this.state.emsg}</div>:null
                        }
                        <input type="email" onChange={(event) => {this.setState({email: event.target.value})}} placeholder="Email" />
                        <input type="password" onChange={(event) => {this.setState({password: event.target.value})}} placeholder="Password" />
                        <button onClick={() => {
                            if (this.state.email && this.state.password) {
                                this.setState({isLoading: true})
                                firebase.auth().signInWithEmailAndPassword(this.state.email.toLocaleLowerCase(), this.state.password)
                                .then(() => {
                                    window.location.href="/Admin1496"
                                    this.setState({isLoading: false})
                                })
                                .catch(error => {
                                    if (error.code === 'auth/wrong-password') {
                                        this.setState({emsg: 'Password is wrong', isLoading: false})
                                        this.setState({ec2: true})
                                    }
                                    if(error.code === 'auth/user-not-found'){
                                        this.setState({emsg: 'User does not exist. Please signup', isLoading: false})
                                    }
                                }); 
                            } else {
                                if (this.state.email === "") {
                                    this.setState({ec: true})
                                }
                                if (this.state.password === "") {
                                    this.setState({ec2: true})
                                }
                            }
                        }}>
                            {
                                this.state.isLoading ?
                                <div className="lds-dual-ring"></div>:"Log in"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
   
}
}

export default Login;