
import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useState } from 'react';
import Home from "./pages/home"
import './App.scss';
import Contact from "./pages/contact";
import Register from "./pages/register";
import Goboy from "./admin";
import firebase from 'firebase';
import Student from "./admin/stu";
import Login from "./admin/login";
import Details from "./admin/details";
import About from "./pages/about";
import Gallery from "./pages/gallery";
import Owe from "./admin/owe";
import Comp from "./admin/comp";
import Change from "./admin/change";
import Analytics from "./admin/analytics";
import Fof from "./f404";
import Stde from "./admin/stde";
import Cliff from "./admin/pdf";
import Pform from "./admin/pform";

const firebaseConfig = {
  apiKey: "AIzaSyC9pflKJNfi2oMJwsvzB7ReVV5iia3o36M",
  authDomain: "almightydb-9bb51.firebaseapp.com",
  databaseURL: "https://almightydb-9bb51-default-rtdb.firebaseio.com",
  projectId: "almightydb-9bb51",
  storageBucket: "almightydb-9bb51.appspot.com",
  messagingSenderId: "953738547018",
  appId: "1:953738547018:web:8c5e5680ceb49c3fa1fbb3",
  measurementId: "G-HLGV3GJ2Q6"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} 

function App() {
  const [users, setUser] = useState(false);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
          setUser(true)
      } else {
        setUser(false)
      }
    });
  }, []);
  if (users) {
    return (
      <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/Contact">
              <Contact />
            </Route>
            <Route exact path="/About">
              <About />
            </Route>
            <Route exact path="/Gallery">
              <Gallery />
            </Route>
            <Route exact path="/Register">
              <Register />
            </Route>
            <Route exact path="/Admin1496" component={Goboy} />
            <Route exact path="/Students" component={Student} />
            <Route exact path="/Analytics" component={Analytics} />
            <Route exact path="/StudentsOwing" component={Owe} />
            <Route exact path="/StudentsCompleted" component={Comp} />
            <Route exact path="/StudentDetails" component={Details} />
            <Route exact path="/Stde" component={Stde} />
            <Route exact path="/ChangePassword" component={Change} />
            <Route exact path="/Receipt" component={Cliff} />
            <Route exact path="/ProgressiveForm" component={Pform} />
            <Route component={Fof} />
          </Switch>
      </BrowserRouter>
    );
    
  } else {
    return (
      <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/Contact">
              <Contact />
            </Route>
            <Route exact path="/About">
              <About />
            </Route>
            <Route exact path="/Gallery">
              <Gallery />
            </Route>
            <Route exact path="/Register">
              <Register />
            </Route>
            <Route exact path="/AdminLogin">
              <Login />
            </Route>
          </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
