import React from 'react';

class Fof extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            mm: false,
            gf:false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
    return (
        <div className="nk">
            <div class="error">404</div>
<br /><br />
<span class="info">Page not found</span>
<img src="http://images2.layoutsparks.com/1/160030/too-much-tv-static.gif" class="static" />
        </div>
    )
   
}
}

export default Fof;