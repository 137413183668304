import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { GiMoneyStack, GiTreeBranch} from 'react-icons/gi';
import { VscLinkExternal} from 'react-icons/vsc';
import { BsCalendar} from 'react-icons/bs';
import { BiUserPlus} from 'react-icons/bi';
import Background from '../assets/1.JPG';
import moment from 'moment';
import firebase from 'firebase';
import Logo from '../assets/logo.jpg'
import * as emailjs from "emailjs-com";

class Goboy extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            mm: false,
            gf: false,
            dat: [],
            dat2: [],
            name: "",
            hold: "",
            search: "",
            filter: "",
            img: "", 
            branch: "",
            isLoading: false,
            isl: false,
            year: "",
            br: "All"
        };
    }
    componentDidMount() {
        // firebase.auth().signOut()
        this._isMounted = true;
        var kk = firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid + "/Logs/").push()
        var key = kk.key;
        firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid + "/Logs/" + key).set({
            key: key,
            created: Date.now()
        })
        
        firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid).on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                this.setState({name: data.firstname + " " + data.lastname, hold: data.hold, img: data.img, branch: data.branch})
              }
            }
          })
          firebase.database().ref("Data4534/").on("value", snapshot => {
              if (this._isMounted) {
                if (snapshot.exists()) {
                  let data = snapshot.val()
                  let dat = Object.values(data);
                  this.setState({dat})
                }
              }
            })
          
        firebase.database().ref("Notifications/").on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                let dat2 = Object.values(data);
                this.setState({dat2})
                // console.log(dat2)
              }
            }
          })
          this.setState({filter: moment().format('YYYY-MM')})
          console.log(moment().format('YYYY-MM'));
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
      const {search, filter, branch, br} = this.state
    return (
        <div className="admin">
            <header>
               <div className="header">
                    <div className="menu" onClick={() => this.setState({gf: true})}>
                        <div></div>
                        <div></div>
                        <div className="lit"></div>
                    </div>
                    <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "65%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "10%", height: 100}}></div>
                    <div className="prof">
                        <div></div>
                        <div className="p1">
                            <div>{this.state.name}</div>
                            <div style={{fontSize: 12, color: "#999"}}>{this.state.hold}</div>
                            <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} style={{fontSize: 12, color: "#f00", cursor: "pointer"}}>Logout</div>
                        </div>
                        <div className="p2" style={{backgroundImage: `url(${this.state.img})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                            
                        </div>
                    </div>
               </div>
            </header>
            <section>
                <div className="section">
                    <div className="s1">
                        {
                            this.state.hold === "Manager" || this.state.hold === "Developer" ?
                            <div className="he0">
                                <input type="month" className="select" onChange={(event) => {this.setState({filter: event.target.value})}} min="2021-01" value={this.state.filter} />
                            </div>: null }
                        {
                            this.state.hold === "Manager" || this.state.hold === "Developer" ?
                            <div className="hed">
                            <div className="l1">
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><GiTreeBranch size="30" color="rgb(72, 255, 0)" /></span>
                                        <span>Branches(Enrolled)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/Students',
                                        state: {date: filter, branch: ""}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div> 
                                        <div style={{fontSize: 12, }}>Ejisu:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return ((item.branch === "Ejisu") && !item.comp && moment(new Date(item.compdate)).format('YYYY-MM') !== filter && moment(new Date(item.created)).format('YYYY-MM') === filter && (item.active))}).length}(₵{this.state.dat.filter(function (item) {return ((item.active) && !item.comp && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Abuakwa:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return ((item.branch === "Abuakwa") && !item.comp && moment(new Date(item.compdate)).format('YYYY-MM') !== filter && moment(new Date(item.created)).format('YYYY-MM') === filter && (item.active))}).length}(₵{this.state.dat.filter(function (item) {return ((item.active) && !item.comp && item.branch === "Abuakwa"  && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1">
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><GiTreeBranch size="30" color="rgb(72, 255, 0)" /></span>
                                        <span>Branches(Dept)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/StudentsOwing',
                                        state: {date: filter, branch: ""}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Ejisu: </div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (
                                            item.active && item.branch === "Ejisu"  && moment(new Date(item.created)).format('YYYY-MM') === filter && 
                                            ((item.promo === "Premium" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) : 
                                            (item.promo === "Standard" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) : 
                                            (item.promo === "Premium Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(item.dep) : 
                                            (item.promo === "Standard Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : 
                                            (item.promo === "Standard Licence" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200) - Number(item.dep) : 
                                            (item.promo === "Premium Licence" ? 1500 - Number(item.dep) : 
                                            (item.promo === "Practicals Only" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null))))))) !== 0 )

                                            && ((item.promo === "Premium" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) : 
                                            (item.promo === "Standard" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) : 
                                            (item.promo === "Premium Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(item.dep) : 
                                            (item.promo === "Standard Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : 
                                            (item.promo === "Standard Licence" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200) - Number(item.dep) : 
                                            (item.promo === "Premium Licence" ? 1500 - Number(item.dep) : 
                                            (item.promo === "Practicals Only" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null))))))) > 0)
                                            )}).length}
                                            
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(Number(b.dep) < 0 ? 0 : Number(b.dep)), 0) - (
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Premium")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000)) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Standard")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500))  + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Premium Polish Up")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200)) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Premium Licence")}).length * 1500) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Standard Licence")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200)) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Practicals Only")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800)) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Standard Polish Up")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800)))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Abuakwa:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter && 
                                        (item.promo === "Premium" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) : 
                                        (item.promo === "Standard" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) : 
                                        (item.promo === "Premium Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(item.dep) : 
                                        (item.promo === "Standard Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) -Number(item.dep) : 
                                        (item.promo === "Standard Licence" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200) - Number(item.dep) : 
                                        (item.promo === "Premium Licence" ? 1500 - Number(item.dep) : 
                                        (item.promo === "Practicals Only" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null))))) ) !== 0))}).length}

                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - (
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Premium")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000)) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Standard")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500))  + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Premium Polish Up")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200)) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Premium Licence")}).length * 1500) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Standard Licence")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200)) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Practicals Only")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800)) + 
                                            Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter && item.promo === "Standard Polish Up")}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800)))})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1">
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BsCalendar size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Students</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/Students',
                                        state: {date: filter, branch: ""}
                                      }} style={{textDecoration: "none",}}>
                                      <VscLinkExternal size="12" color="rgb(72, 255, 0)" />
                                    </Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Active Students:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Inactive Students:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (!item.active && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (!item.active && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Completed Students:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.comp && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{
                                        (Number(this.state.dat.filter(function (item) {return (item.active && item.comp && item.promo === "Premium" && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && item.comp && item.promo === "Standard" && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && item.comp && item.promo === "Premium Polish Up" && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && item.comp && item.promo === "Premium Licence" && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length * 1500) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && item.comp && item.promo === "Standard Licence" && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && item.comp && item.promo === "Practicals Only" && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && item.comp && item.promo === "Standard Polish Up" && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800)))})</div>
                                        {/* <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (!item.active && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce(((a, b) => a + Number(b.dep), 0))})</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="l1">
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><GiMoneyStack size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Su...:</span>
                                    </div>
                                    {/* <Link to="/Students"><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link> */}
                                    <select value={this.state.br} onChange={(e) => this.setState({br: e.target.value})}>
                                        <option value="All">All</option>
                                        <option value="Ejisu">Ejisu</option>
                                        <option value="Abuakwa">Abuakwa</option>
                                    </select>      
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Collected amount</div>
                                        <div style={{marginTop: 5, color: "#999" }}>₵{this.state.dat.filter(function (item) {return ((item.active === "active") && item.branch === (br === "All" ? item.branch : br) && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY') )}).reduce((a, b) => a + Number(b.dep), 0)}</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Amount Active Students Owe</div>
                                        <div style={{marginTop: 5, color: "#999" }}>₵{(this.state.dat.filter(function (item) {return (item.active  && item.branch === (br === "All" ? item.branch : br) && !item.comp && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).reduce((a, b) => a + Number(b.dep), 0)) - (
                                        Number(this.state.dat.filter(function (item) {return (item.active && !item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Premium" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && !item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Standard" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && !item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Premium Polish Up" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && !item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Premium Licence" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * 1500) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && !item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Standard Licence" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && !item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Practicals Only" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800)) + 
                                        Number(this.state.dat.filter(function (item) {return (item.active && !item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Standard Polish Up" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800))) }</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Amount Completed Students Owe</div>
                                        <div style={{marginTop: 5, color: "#999" }}>₵{(this.state.dat.filter(function (item) {return (item.active  && item.branch === (br === "All" ? item.branch : br) && item.comp && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).reduce((a, b) => a + Number(b.dep), 0)) - 
                                        (Number(this.state.dat.filter(function (item) {return (item.active && item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Premium" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000)) +
                                         Number(this.state.dat.filter(function (item) {return (item.active && item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Standard" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500)) + 
                                         Number(this.state.dat.filter(function (item) {return (item.active && item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Premium Polish Up" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200)) +  
                                         Number(this.state.dat.filter(function (item) {return (item.active && item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Premium Licence" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * 1500) + 
                                         Number(this.state.dat.filter(function (item) {return (item.active && item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Standard Licence" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200)) + 
                                         Number(this.state.dat.filter(function (item) {return (item.active && item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Practicals Only" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800)) + 
                                         Number(this.state.dat.filter(function (item) {return (item.active && item.comp  && item.branch === (br === "All" ? item.branch : br) && item.promo === "Standard Polish Up" && moment(new Date(item.created)).format('YYYY') === moment(filter).format('YYYY'))}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800))) }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Enrollment(active)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/Students',
                                        state: {date: filter, branch: ""}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Premium:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && !item.comp && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter && !item.comp && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && !item.comp && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && !item.comp && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && !item.comp && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && !item.comp && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && !item.comp && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && !item.comp && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard Polish Up") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && !item.comp && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard Licence") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Practicals Only:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && !item.comp && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Practicals Only") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Enrollment(Dept)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/StudentsOwing',
                                        state: {date: filter, branch: ""}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Premium:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter  && (1500 - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * 1500)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Practicals Only:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800))})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Ejisu(active)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/Students',
                                        state: {date: filter, branch: "Ejisu"}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Premium:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {
                                            return (
                                                item.active && item.branch === "Ejisu" 
                                                && !item.comp 
                                                && (item.promo === "Premium") 
                                                && moment(new Date(item.created)).format('YYYY-MM') === filter 
                                                && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length
                                                }(₵{this.state.dat.filter(function (item) {
                                                    return (
                                                        item.active && item.branch === "Ejisu" 
                                                        && (item.promo === "Premium") 
                                                        && moment(new Date(item.created)).format('YYYY-MM') === filter 
                                                        && !item.comp && moment(new Date(item.compdate)).format('YYYY-MM') != filter)
                                                        }).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && !item.comp && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Polish Up") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && !item.comp && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Licence") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && !item.comp && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && !item.comp && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Polish Up") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && !item.comp && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Licence") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Practicals Only:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && !item.comp && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Practicals Only") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Ejisu(Dept)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/StudentsOwing',
                                        state: {date: filter, branch: "Ejisu"}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Premium:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter  && (1500 - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * 1500)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Practicals Only:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800))})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Ejisu(Completed)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/StudentsCompleted',
                                        state: {date: filter, branch: "Ejisu"}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Premium:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.comp && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter && item.comp && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.comp && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Polish Up") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.comp && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Premium Licence") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.comp && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.comp && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Polish Up") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.comp && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Standard Licence") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Practicals Only:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.comp && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && (item.promo === "Practicals Only") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Abuakwa(active)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/Students',
                                        state: {date: filter, branch: "Abuakwa"}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Premium:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && !item.comp && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter && !item.comp && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && !item.comp && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Polish Up") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && !item.comp && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Licence") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && !item.comp && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && !item.comp && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Polish Up") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && !item.comp && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Licence") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Practicals Only:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && !item.comp && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Practicals Only") && !item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') != filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Abuakwa(Dept)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/StudentsOwing',
                                        state: {date: filter, branch: "Abuakwa"}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Premium:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter  && (1500 - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * 1500)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter  && (1500 - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200))})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Practicals Only:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter  && ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) !== 0))}).length}
                                        (₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0) - Number(this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800))})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Abuakwa(Completed)</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/StudentsCompleted',
                                        state: {date: filter, branch: "Abuakwa"}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Premium:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.comp && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium") && moment(new Date(item.created)).format('YYYY-MM') === filter && item.comp && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.comp && (item.promo === "Premium Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Polish Up") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Premium Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.comp && (item.promo === "Premium Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Premium Licence") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.comp && (item.promo === "Standard") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Polish Up:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.comp && (item.promo === "Standard Polish Up") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Polish Up") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Standard Licence:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.comp && (item.promo === "Standard Licence") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Standard Licence") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Practicals Only:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.comp && (item.promo === "Practicals Only") && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).length}(₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && (item.promo === "Practicals Only") && item.comp && moment(new Date(item.created)).format('YYYY-MM') === filter && moment(new Date(item.compdate)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)})</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Rose Emefa Nyahe</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/Students',
                                        state: {date: filter, branch: "Ejisu"}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Total Number Of Students:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length}</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Expected Amount:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>₵{
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Premium" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Premium Polish Up" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Premium Licence" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * 1500) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Standard Licence" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Practicals Only" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Standard" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Standard Polish Up" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800))
                                        }</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Total Amount Recieved:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)}</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Total Dept:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>₵{
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)) -
                                        ((this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Premium" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Premium Polish Up" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Premium Licence" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * 1500) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Standard Licence" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Practicals Only" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Standard" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Ejisu" && item.promo === "Standard Polish Up" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800))
                                        )}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="l1" style={{marginTop: 10}}>
                                <div className="he">
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                                        <span><BiUserPlus size="20" color="rgb(72, 255, 0)" /></span>
                                        <span>Saddia Alhassan</span>
                                    </div>
                                    <Link to={{
                                        pathname:'/Students',
                                        state: {date: filter, branch: "Abuakwa"}
                                      }}><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></Link>
                                </div>
                                <div style={{marginTop: 20, }}>
                                    <div>
                                        <div style={{fontSize: 12, }}>Total Number Of Students:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length}</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Expected Amount:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>₵{
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Premium" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Premium Polish Up" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Premium Licence" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * 1500) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Standard Licence" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Practicals Only" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Standard" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Standard Polish Up" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800))
                                        }</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Total Amount Recieved:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>₵{this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)}</div>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        <div style={{fontSize: 12, }}>Total Dept:</div>
                                        <div style={{marginTop: 5, color: "#999" }}>₵{
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).reduce((a, b) => a + Number(b.dep), 0)) -
                                        ((this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Premium" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Premium Polish Up" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Premium Licence" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * 1500) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Standard Licence" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Practicals Only" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Standard" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500)) +
                                        (this.state.dat.filter(function (item) {return (item.active && item.branch === "Abuakwa" && item.promo === "Standard Polish Up" && moment(new Date(item.created)).format('YYYY-MM') === filter)}).length * (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800))
                                        )}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            :null
                        }
                        
                        {
                            this.state.hold === "Manager" || this.state.hold === "Developer" ?
                            <div className="ns">
                                <div className="sm">
                                    <div>New Students({this.state.dat.filter(function (item) {return (!item.active && item.fname)}).length})</div>
                                    <div><input type="search" onChange={(e) => this.setState({search: e.target.value})} placeholder="Search..." /></div>
                                </div>
                                <div className="hggh">
                                    <div style={{width: "2%", }}>No</div>
                                    <div style={{width: "36%", }}>Name</div>
                                    <div style={{width: "9%", }}>Amount</div>
                                    <div style={{width: "9%", }}>Dept</div>
                                    <div style={{width: "17%", }}>Branch</div>
                                    {/* <div style={{width: "10%", }}>Payment</div> */}
                                    <div style={{width: "15%", }}>Package</div>
                                    <div style={{width: "10%", }}></div>
                                </div>
                                <div className="oi">
                                    {
                                        this.state.dat.filter(function (item) {
                                            const itemData = (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo + " " + item.branch) ? (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo + " " + item.branch).toUpperCase() : ''.toUpperCase();
                                            const textData = search.toUpperCase();
                                            return (
                                                (!item.active ? itemData.indexOf(textData) > -1 : "") && item.fname
                                            )
                                        }).sort((a, b) => new Date(a) < new Date(b) ? 1 : -1).map((item, index) => {
                                            return (
                                                <div key={item.id} className="gh2">
                                                    <div style={{width: "2%", }}>{index + 1}.</div>
                                                    <div style={{width: "36%", }}>{item.fname + " " + item.mname + " " + item.lname}</div>
                                                    <div style={{width: "9%", }}>₵{item.dep} </div>
                                                    <div style={{width: "9%", }}>₵{item.promo === "Premium" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) : (item.promo === "Standard" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) : (item.promo === "Premium Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(item.dep) : (item.promo === "Standard Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null)))}</div>
                                                    <div style={{width: "17%", }}>{item.branch}</div>
                                                    {/* <div style={{width: "10%", }}>{item.pay}</div> */}
                                                    <div style={{width: "15%", }}>{item.promo}</div>
                                                    <button onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({isl: true})
                                                        firebase.database().ref(`Data4534/${item.id}`).remove().then(() => {
                                                            firebase.database().ref(`Notifications/${item.id}`).remove().then(() => {
                                                                this.setState({isl: false})
                                                            })
                                                        })
                                                    }} style={{width: "5%", backgroundColor: 'transparent', borderWidth: 0, cursor: 'pointer', color: "rgb(255, 0, 0)" }}>
                                                        {
                                                            this.state.isl ? "..." : "Delete"
                                                        }
                                                    </button>
                                                    <div onClick={() => {
                                                        this.setState({isLoading: true})
                                                        {/* var data = {
                                                            to_email: `${item.email}`,
                                                            to_name: `${item.fname + " " + item.mname + " " + item.lname}`,
                                                        }; */}
                                                    
                                                        {/* emailjs.send("service_yge0dmd", "template_kg9hknt", data, "user_sKozjfeVcu2CekrnGebjb").then(
                                                            (response) => {
                                                            console.log(response.status, response.text); */}
                                                                firebase.database().ref("Data4534/" + item.id).update({
                                                                    active: "active",
                                                                    acceptedDate: Date.now()
                                                                }).then(() => {
                                                                    var fft = firebase.database().ref("Notifications/").push()
                                                                    var key = fft.key
                                                                    firebase.database().ref("Data4534/" + item.id + "/depid/" + key).set({
                                                                        depi: item.dep,
                                                                        id: key,
                                                                        created: Date.now(),
                                                                        name: item.fname + (item.mname ? (" " + item.mname) : " ") + item.lname,
                                                                        dep: item.promo === "Premium" ? ((moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep))
                                                                        : item.promo === "Standard" ? ((moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep)) : 
                                                                        (item.promo === "Premium Polish Up" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1800 : 2200) - Number(item.dep) : 
                                                                        (item.promo === "Standard Polish Up" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1500 : 1800) - Number(item.dep) : 
                                                                        (item.promo === "Standard Licence" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1000 : 1200) - Number(item.dep) : 
                                                                        (item.promo === "Premium Licence" ? 1500 - Number(item.dep) : 
                                                                        (item.promo === "Practicals Only" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null)))))
                                                                    }).then(() => {
                                                                        firebase.database().ref("Records/" + key).set({
                                                                            name: item.fname + (item.mname ? (" " + item.mname) : " ") + item.lname,
                                                                            created: Date.now(),
                                                                            key:  key,
                                                                            branch: item.branch,
                                                                            student: key,
                                                                            promo: item.promo,
                                                                            depi: item.dep,
                                                                            dep: item.promo === "Premium" ? ((moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep))
                                                                            : item.promo === "Standard" ? ((moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep)) : 
                                                                            (item.promo === "Premium Polish Up" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1800 : 2200) - Number(item.dep) : 
                                                                            (item.promo === "Standard Polish Up" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1500 : 1800) - Number(item.dep) : 
                                                                            (item.promo === "Standard Licence" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1000 : 1200) - Number(item.dep) : 
                                                                            (item.promo === "Premium Licence" ? 1500 - Number(item.dep) : 
                                                                            (item.promo === "Practicals Only" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null)))))
                                                                        })
                                                                    }).then(() => {
                                                                        this.setState({isLoading: false})
                                                                    })
                                                                })
                                                            {/* },
                                                            function (err) {
                                                            console.log(err);
                                                            }
                                                        );                                     */}
                                                    }} style={{width: "5%", cursor: 'pointer', color: "rgb(72, 255, 0)" }}>
                                                        {
                                                            this.state.isLoading ? "..." : "Enroll"
                                                        }
                                                        </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        :
                            <div className="ns">
                                <div className="sm">
                                    <div>New Students({this.state.dat.filter(function (item) {return (!item.active && item.fname && (item.branch ? (item.branch === branch) : true))}).length})</div>
                                    <div><input type="search" onChange={(e) => this.setState({search: e.target.value})} placeholder="Search..." /></div>
                                </div>
                                <div className="hggh">
                                    <div style={{width: "2%", }}>No</div>
                                    <div style={{width: "36%", }}>Name</div>
                                    <div style={{width: "9%", }}>Amount</div>
                                    <div style={{width: "9%", }}>Dept</div>
                                    <div style={{width: "17%", }}>Branch</div>
                                    {/* <div style={{width: "10%", }}>Payment</div> */}
                                    <div style={{width: "15%", }}>Package</div>
                                    <div style={{width: "10%", }}></div>
                                </div>
                                <div className="oi">
                                {
                                    this.state.dat.filter(function (item) {
                                        const itemData = (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo) ? (item.fname + " " + (item.mname ? item.mname : item.lname) + " " + item.lname + " " + item.promo).toUpperCase() : ''.toUpperCase();
                                        const textData = search.toUpperCase();
                                        return (
                                            (!item.active ? itemData.indexOf(textData) > -1 : "") && item.fname && (item.branch ? (item.branch === branch) : true)
                                        )
                                    }).sort((a, b) => new Date(a) < new Date(b) ? 1 : -1).map((item, index) => {
                                        return (
                                            <div key={item.id} className="gh2">
                                                <div style={{width: "2%", }}>{index + 1}.</div>
                                                <div style={{width: "36%", }}>{item.fname + " " + item.mname + " " + item.lname}</div>
                                                <div style={{width: "9%", }}>₵{item.dep} </div>
                                                <div style={{width: "9%", }}>₵{item.promo === "Premium" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep) : (item.promo === "Standard" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep) : (item.promo === "Premium Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(item.dep) : (item.promo === "Standard Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null)) )}</div>
                                                <div style={{width: "17%", }}>{item.branch}</div>
                                                {/* <div style={{width: "10%", }}>{item.pay}</div> */}
                                                <div style={{width: "15%", }}>{item.promo}</div>
                                                <div onClick={() => {
                                                    {/* var data = {
                                                        to_email: `${item.email}`,
                                                        to_name: `${item.fname + " " + item.mname + " " + item.lname}`,
                                                    }; */}
                                                
                                                    {/* emailjs.send("service_yge0dmd", "template_kg9hknt", data, "user_sKozjfeVcu2CekrnGebjb").then(
                                                        function (response) {
                                                        console.log(response.status, response.text); */}
                                                        firebase.database().ref("Data4534/" + item.id).update({
                                                            active: "active",
                                                            acceptedDate: Date.now()
                                                        }).then(() => {
                                                            var fft = firebase.database().ref("Notifications/").push()
                                                            var key = fft.key
                                                            firebase.database().ref("Data4534/" + item.id + "/depid/" + key).set({
                                                                depi: item.dep,
                                                                id: key,
                                                                created: Date.now(),
                                                                name: item.fname + (item.mname ? (" " + item.mname) : " ") + item.lname,
                                                                dep: item.promo === "Premium" ? ((moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep))
                                                                : item.promo === "Standard" ? ((moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep)) : 
                                                                (item.promo === "Premium Polish Up" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1800 : 2200) - Number(item.dep) : 
                                                                (item.promo === "Standard Polish Up" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1500 : 1800) - Number(item.dep) : 
                                                                (item.promo === "Standard Licence" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1000 : 1200) - Number(item.dep) : 
                                                                (item.promo === "Premium Licence" ? 1500 - Number(item.dep) : 
                                                                (item.promo === "Practicals Only" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null)))))
                                                            }).then(() => {
                                                                firebase.database().ref("Records/" + key).set({
                                                                    name: item.fname + (item.mname ? (" " + item.mname) : " ") + item.lname,
                                                                    created: Date.now(),
                                                                    key:  key,
                                                                    branch: item.branch,
                                                                    student: key,
                                                                    promo: item.promo,
                                                                    depi: item.dep,
                                                                    dep: item.promo === "Premium" ? ((moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 2000  : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(item.dep))
                                                                    : item.promo === "Standard" ? ((moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(item.dep)) : 
                                                                    (item.promo === "Premium Polish Up" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1800 : 2200) - Number(item.dep) : 
                                                                    (item.promo === "Standard Polish Up" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1500 : 1800) - Number(item.dep) : 
                                                                    (item.promo === "Standard Licence" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1000 : 1200) - Number(item.dep) : 
                                                                    (item.promo === "Premium Licence" ? 1500 - Number(item.dep) : 
                                                                    (item.promo === "Practicals Only" ? (moment(Date.now()).format('YYYY') === "2021" || moment(Date.now()).format('YYYY-MM') === "2022-01" ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(item.dep) : null)))))
                                                                })
                                                            }).then(() => {
                                                                this.setState({isLoading: false})
                                                            })
                                                        })
                                                        {/* },
                                                        function (err) {
                                                        console.log(err);
                                                        }
                                                    );                                     */}
                                                }} style={{width: "10%", cursor: 'pointer', color: "rgb(72, 255, 0)" }}>
                                                    {
                                                        this.state.isLoading ? "..." : "Enroll"
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            </div>
                        }
                    </div>
                {
                    this.state.hold === "Manager" || this.state.hold === "Developer" ?
                    <div className="s2">
                        <div className="holo" style={{fontSize: 20, marginBottom: 10}}>Notification</div>
                        {
                            this.state.dat2.filter(function(item) {return (item.fname || (item.student && item.name))}).sort((a, b) => new Date(a) < new Date(b) ? 1 : -1).map((item) => {
                                return (
                                    <Link to={{
                                        pathname:'/Stde',
                                        state: {name: `${item.student}`}
                                      }} key={item.id} style={{width: "100%", pointerEvents: item.reason === "Register" ? 'none' : 'auto', textDecoration: "none", color: "#000",}}>
                                        <div style={{width: "100%", display: 'flex', marginTop: 10, flexDirection: "row", justifyContent: "space-between"}}>
                                            <div>
                                                <div style={{fontSize: 14}}>{item.reason === "Register" ? "New Student Registration" : "Student Update"}</div>
                                                <div style={{fontSize: 12, color: "#999"}}>{item.reason === "Register" ? (item.fname + " " + item.mname + " " + item.lname) : item.name + " Just made an Update to " + item.student}</div>
                                            </div>
                                            <div style={{fontSize: 12}}>{moment(new Date(item.created)).format("MMM Do YY")}</div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>:
                    <div className="s2">
                    <div className="holo" style={{fontSize: 20, marginBottom: 10}}>Notification</div>
                    {
                        this.state.dat.filter(function(item) {return (item.fname)}).sort((a, b) => new Date(a) < new Date(b) ? 1 : -1).map((item) => {
                            return (
                                <div key={item.id} style={{width: "100%", marginTop: 10,}}>
                                    <div style={{width: "100%", display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
                                        <div>
                                            <div style={{fontSize: 14}}>New Student Registration</div>
                                            <div style={{fontSize: 12, color: "#999"}}>{item.fname + " " + item.mname + " " + item.lname}</div>
                                        </div>
                                        <div style={{fontSize: 12}}>{moment(new Date(item.created)).format("MMM Do YY")}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>}
                </div>
            </section>
            {
                this.state.gf ?
                <div className="mr">
                    <div className="close">
                        <div></div>
                        <div onClick={() => this.setState({gf: false})}>x</div>
                    </div>
                    <div className="kij">
                        <Link to="/Analytics"><span>Analytics</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Students"><span>Update Fee</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsOwing"><span>Students Owing</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsCompleted"><span>Completed Students</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/ChangePassword"><span>Change Password</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} className="lo" style={{marginTop: 30}}><span>Logout</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></div></div>
                </div>:null
            }
        </div>
    )
   
}
}

export default Goboy;