import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { VscLinkExternal} from 'react-icons/vsc';
import Background from '../assets/1.JPG';
import moment from 'moment';
import Logo from '../assets/logo.jpg'
import firebase from 'firebase';

class Details extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            gf: false,
            dat: [],
            datp: [],
            name: "",
            hold: "",
            search: "",
            dep: "",
            isLoading:false,
            image: "",
            fname: "",
            mname: "",
            lname: "",
            dob: "",
            pob: "",
            pa: "",
            ra: "",
            email: "",
            phone: "",
            id: "",
            idnum: "",
            imre: "",
            promo: "",
            dep: "",
            depi: "",
            pay: "",
            branch: "",
            comp: "",
            imrp: "",
            filter: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        
        firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid).on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                this.setState({name: data.firstname + " " + data.lastname, hold: data.hold, img: data.img})
              }
            }
          })
          
        firebase.database().ref("Data4534/" + this.props.location.state.name).on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                this.setState({
                    image: data.image,
                    fname: data.fname,
                    mname: data.mname,
                    lname: data.lname,
                    dob: data.dob,
                    pob: data.pob,
                    pa: data.pa,
                    ra: data.ra,
                    email: data.email,
                    phone: data.phone,
                    id: data.idtype,
                    idnum: data.idnum,
                    imre: data.imre,
                    promo: data.promo,
                    dep: data.dep,
                    comp: data.comp,
                    branch: data.branch,
                    imrp: data.imrp,
                    filter: data.created
                })
              }
            }
          })
          
        firebase.database().ref("Data4534/" + this.props.location.state.name + "/depid/").on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                let datp = Object.values(data);
                this.setState({datp})
              }
            }
          })
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
    const {search, filter} = this.state
    return (
        <div className="admin">
            <header>
               <div className="header">
                    <div className="menu" onClick={() => this.setState({gf: true})}>
                        <div></div>
                        <div></div>
                        <div className="lit"></div>
                    </div>
                    <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "65%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "10%", height: 100}}></div>
                    <div className="prof">
                        <div></div>
                        <div className="p1">
                            <div>{this.state.name}</div>
                            <div style={{fontSize: 12, color: "#999"}}>{this.state.hold}</div>
                            <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} style={{fontSize: 12, color: "#f00", cursor: "pointer"}}>Logout</div>
                        </div>
                        <div className="p2" style={{backgroundImage: `url(${this.state.img})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                            
                        </div>
                    </div>
               </div>
            </header>
            <section>
                <div className="details">
                    <div className="imag">
                        <div></div>
                        <div className="pim" style={{backgroundImage: `url(${this.state.image})`, backgroundSize: "cover", backgroundPosition: "center"}}></div>
                    </div>
                    <div className="we2">
                        <div>Name</div>
                        <div className="fr">
                            <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.fname} onChange={(event) => {this.setState({fname: event.target.value})}} placeholder="First Name*" />
                            <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.mname} onChange={(event) => {this.setState({mname: event.target.value})}} placeholder="Middle Name" />
                            <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.lname} onChange={(event) => {this.setState({lname: event.target.value})}} placeholder="Last Name*" />
                        </div>
                    </div>
                    <div className="we2">
                        <div>Date of Birth</div>
                        <div className="fr2">
                            <input disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} style={{width: "100%"}} value={this.state.dob} type="date" onChange={(event) => {this.setState({dob: event.target.value})}} />
                            
                            <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.pob} onChange={(event) => {this.setState({pob: event.target.value})}} placeholder="Place Of Birth*" />
                        </div>
                    </div>
                    <div className="we2">
                        <div>Postal Address</div>
                        <div className="fr3">
                            <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.pa} onChange={(event) => {this.setState({pa: event.target.value})}} placeholder="Postal Address" />
                        </div>
                    </div>
                    <div className="we2">
                        <div>Residential Address</div>
                        <div className="fr3">
                        <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.ra} onChange={(event) => {this.setState({ra: event.target.value})}} placeholder="Residential Address*" />
                        </div>
                    </div>
                    <div className="we2">
                        <div>Email</div>
                        <div className="fr2">
                        <input type="email" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.email} onChange={(event) => {this.setState({email: event.target.value})}} placeholder="Email*" />
                        <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.phone} onChange={(event) => {this.setState({phone: event.target.value})}} placeholder="Telephone Number*" />
                        </div>
                    </div>
                    <div className="we2">
                        <div>{this.state.id} ID</div>
                        <div className="fr3">
                        <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.idnum} onChange={(event) => {this.setState({idnum: event.target.value})}} />
                    </div>
                    </div>
                    <div className="we2">
                        <div>Immediate Relative</div>
                        <div className="fr3">
                        <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.imre} onChange={(event) => {this.setState({imre: event.target.value})}} placeholder="Immediate Relative*" />
                        </div>
                    </div>
                    <div className="we2">
                        <div>Immediate Relative Phone Number</div>
                        <div className="fr3">
                        <input type="text" disabled={this.state.hold === "Manager" || this.state.hold === "Developer" ? false  : true} value={this.state.imrp} onChange={(event) => {this.setState({imrp: event.target.value})}} placeholder="Immediate Relative Phone Number*" />
                        </div>
                    </div>
                    <div className="we2">
                        <div className="fr2">
                            <select style={{width: "100%", padding: 10, borderColor: "#f4f5f9"}} value={this.state.promo} onChange={(event) => {this.setState({promo: event.target.value})}} >
                                {/* <option value="" disabled selected>Select Package*</option> */}
                                {
                                    this.state.promo === "Premium" ?
                                    <option value="Premium" selected>Premium</option>:
                                    <option value="Premium">Premium</option>
                                }
                                {
                                    this.state.promo === "Premium Polish Up" ?
                                    <option value="Premium Polish Up" selected>Premium Polish Up</option>:
                                    <option value="Premium Polish Up">Premium Polish Up</option>
                                }
                                {
                                    this.state.promo === "Premium Licence" ?
                                    <option value="Premium Licence" selected>Premium Licence</option>:
                                    <option value="Premium Licence">Premium Licence</option>
                                }
                                {
                                    this.state.promo === "Standard" ?
                                    <option value="Standard" selected>Standard</option>:
                                    <option value="Standard">Standard</option>
                                }
                                {
                                    this.state.promo === "Standard Polish Up" ?
                                    <option value="Standard Polish Up" selected>Standard Polish Up</option>:
                                    <option value="Standard Polish Up">Standard Polish Up</option>
                                }
                                {
                                    this.state.promo === "Standard Licence" ?
                                    <option value="Standard Licence" selected>Standard Licence</option>:
                                    <option value="Standard Licence">Standard Licence</option>
                                }
                                {
                                    this.state.promo === "Practicals Only" ?
                                    <option value="Practicals Only" selected>Practicals Only</option>:
                                    <option value="Practicals Only">Practicals Only</option>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="we2">
                        <div className="fr2">
                            <input type="number" onChange={(event) => {this.setState({depi: event.target.value})}} placeholder="Deposite*" />
                            <input type="text" readonly="readonly" style={{color: "#999"}} value={"₵" + (this.state.promo === "Premium" ? ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(this.state.dep)) - Number(this.state.depi) : 
                            (this.state.promo === "Standard" ? ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(this.state.dep) - Number(this.state.depi)) : 
                            (this.state.promo === "Premium Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(this.state.dep) - Number(this.state.depi) : 
                            (this.state.promo === "Standard Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) - Number(this.state.dep) - Number(this.state.depi) : 
                            (this.state.promo === "Standard Licence" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200) - Number(this.state.dep) - Number(this.state.depi) : 
                            (this.state.promo === "Premium Licence" ? 1500 - Number(this.state.dep) - Number(this.state.depi) : 
                            (this.state.promo === "Practicals Only" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(this.state.dep) - Number(this.state.depi) : null)))))))} placeholder="Balance" />
                        </div>
                    </div>
                    <div className="we3">
                        <button onClick={(e) => {
                            e.preventDefault()
                            if (this.state.fname && this.state.lname && this.state.dob && this.state.pob && this.state.ra && this.state.email && this.state.imre && this.state.promo) {
                                this.setState({isLoading: true})
                                        firebase.database().ref("Data4534/" + this.props.location.state.name).update({
                                            image: this.state.image,
                                            fname: this.state.fname,
                                            mname: this.state.mname,
                                            lname: this.state.lname,
                                            dob: this.state.dob,
                                            pob: this.state.pob,
                                            pa: this.state.pa,
                                            ra: this.state.ra,
                                            email: this.state.email,
                                            phone: this.state.phone,
                                            idtype: this.state.id,
                                            idnum: this.state.idnum,
                                            imre: this.state.imre,
                                            promo: this.state.promo,
                                            dep: Number(this.state.dep) + Number(this.state.depi),
                                            branch: this.state.branch,
                                        }).then(() => {
                                            var fft = firebase.database().ref("Notifications/").push()
                                            var keyy = fft.key
                                            firebase.database().ref("Notifications/" + keyy).set({
                                                name: this.state.name,
                                                reason: "update",
                                                created: Date.now(),
                                                key: keyy,
                                                student: this.state.fname + " " + this.state.mname + " " + this.state.lname,
                                            })
                                            if (Number(this.state.depi) !== 0) {
                                                var ft = firebase.database().ref("Data4534/" + this.props.location.state.name + "/depid/").push()
                                                var key = ft.key
                                                firebase.database().ref("Data4534/" + this.props.location.state.name + "/depid/" + key).set({
                                                    id: key,
                                                    created: Date.now(),
                                                    name: this.state.name,
                                                    depi: this.state.depi,
                                                    dep: this.state.promo === "Premium" ? ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(this.state.dep))
                                                        : this.state.promo === "Standard" ? ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(this.state.dep)) : 
                                                        (this.state.promo === "Premium Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(this.state.dep) : 
                                                        (this.state.promo === "Standard Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) - Number(this.state.dep) : 
                                                        (this.state.promo === "Standard Licence" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200) - Number(this.state.dep) : 
                                                        (this.state.promo === "Premium Licence" ? 1500 - Number(this.state.dep) : 
                                                        (this.state.promo === "Practicals Only" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(this.state.dep) : null)))))
                                                }).then(() => {
                                                    firebase.database().ref("Records/" + key).set({
                                                        depi: Number(this.state.depi),
                                                        name: this.state.fname + (this.state.mname ? (" " + this.state.mname) : " ") + this.state.lname,
                                                        created: Date.now(),
                                                        key:  key,
                                                        branch: this.state.branch,
                                                        student: this.props.location.state.name,
                                                        promo: this.state.promo,
                                                        dep: this.state.promo === "Premium" ? ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 2000 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2500 : 3000) - Number(this.state.dep))
                                                        : this.state.promo === "Standard" ? ((moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1600 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 2000 : 2500) - Number(this.state.dep)) : 
                                                        (this.state.promo === "Premium Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1800 : 2200) - Number(this.state.dep) : 
                                                        (this.state.promo === "Standard Polish Up" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1500 : 1800) - Number(this.state.dep) : 
                                                        (this.state.promo === "Standard Licence" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1000 : 1200) - Number(this.state.dep) : 
                                                        (this.state.promo === "Premium Licence" ? 1500 - Number(this.state.dep) : 
                                                        (this.state.promo === "Practicals Only" ? (moment(filter).diff(moment('2022-01-31'), 'days') <=0 ? 1200 : moment(filter).diff(moment('2022-10-31'), 'days') <=0 ? 1500 : 1800) - Number(this.state.dep) : null)))))
                                                    }).then(() => {
                                                        this.setState({
                                                            isLoading: false,
                                                            depi: "",
                                                        })
                                                    })
                                                })
                                            }
                                        })
                                    }}}
                        >
                            {
                                this.state.isLoading ?
                                <div className="lds-dual-ring"></div>: "Update"
                            }
                        </button>
                        <div className="jh">
                        {
                            this.state.hold === "Manager" || this.state.hold === "Developer" ?
                            <div onClick={() => {
                                firebase.database().ref("Data4534/" + this.props.location.state.name).update({
                                    comp: "Completed",
                                    compdate: Date.now(),
                                })
                            }} className="knm" style={{padding: 10, cursor: 'pointer', color: "#000"}}>{this.state.comp ? "Completed✔" : "Completed?"}</div>:null
                        }
                            <Link to={{
                                pathname:'/ProgressiveForm',
                                state: {id: this.props.location.state.name}  
                            }}>P - Form</Link>
                            <Link to={{
                                pathname:'/Receipt',
                                state: {id: this.props.location.state.name}  
                            }}>Generate Receipt</Link>
                        </div>
                    </div>
            {/* <div className="updated">Update Successfull</div> */}
                    {
                        this.state.datp.length <= 0 ? "" :
                        <h1>Notifications</h1>
                    }
                    {
                        this.state.datp.sort((a, b) => new Date(a) < new Date(b) ? 1 : -1).map((item, index) => {
                            return (
                                <div key={index} style={{width: "100%", marginTop: 10, borderRadius: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 14, borderWidth: 1, borderStyle: "dotted", borderColor: "#f4f5f9", paddingTop: 10, paddingBottom: 10}}>
                                    <div>Amount Payed is ₵{item.depi}. {this.state.fname + " " + this.state.mname + " " + this.state.lname} Owing ₵{item.dep}. Update by ({item.name})</div>
                                    <div style={{fontSize: 12}}>{moment(new Date(item.created)).format("MMM Do YY, LT")}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </section>
            {
                this.state.gf ?
                <div className="mr">
                    <div className="close">
                        <div></div>
                        <div onClick={() => this.setState({gf: false})}>x</div>
                    </div>
                    <div className="kij">
                        <Link to="/Admin1496"><span>Home</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Analytics"><span>Analytics</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Students"><span>Update Fee</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsOwing"><span>Students Owing</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsCompleted"><span>Completed Students</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/ChangePassword"><span>Change Password</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} className="lo" style={{marginTop: 30}}><span>Logout</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></div></div>
                </div>:null
            }
        </div>
    )
   
}
}

export default Details;