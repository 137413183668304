import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { GiMoneyStack} from 'react-icons/gi';
import { VscLinkExternal} from 'react-icons/vsc';
import { BsCalendar} from 'react-icons/bs';
import { BiUserPlus} from 'react-icons/bi';
import Background from '../assets/1.JPG';
import moment from 'moment';
import firebase from 'firebase';
import Logo from '../assets/logo.jpg'

class Change extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            gf: false,
            dat: [],
            name: "",
            hold: "",
            search: "",
            npa: "",
            cpa: "",
            email: "",
            isLoading: "",
            lip: "",
            msg: ""
        };
    }
    componentDidMount() {
        this._isMounted = true;
        
        firebase.database().ref("AdminBossman7152/" + firebase.auth().currentUser.uid).on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                this.setState({name: data.firstname + " " + data.lastname, hold: data.hold, img: data.img, email: data.email})
              }
            }
          })
          
        firebase.database().ref("Data4534/").on("value", snapshot => {
            if (this._isMounted) {
              if (snapshot.exists()) {
                let data = snapshot.val()
                let dat = Object.values(data);
                this.setState({dat})
              }
            }
          })
      }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
  render() {
    const {search} = this.state
    return (
        <div className="admin">
            
            <header>
               <div className="header">
                    <div className="menu" onClick={() => this.setState({gf: true})}>
                        <div></div>
                        <div></div>
                        <div className="lit"></div>
                    </div>
                    <div style={{backgroundImage: `url(${Logo})`, backgroundSize: "65%", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "10%", height: 100}}></div>
                    <div className="prof">
                        <div></div>
                        <div className="p1">
                            <div>{this.state.name}</div>
                            <div style={{fontSize: 12, color: "#999"}}>{this.state.hold}</div>
                            <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} style={{fontSize: 12, color: "#f00", cursor: "pointer"}}>Logout</div>
                        </div>
                        <div className="p2" style={{backgroundImage: `url(${this.state.img})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                            
                        </div>
                    </div>
               </div>
            </header>
            <section>
                <div className="change">
                    <div className="ano">
                        <div>{this.state.lip}</div>
                        <input type="password" onChange={(event) => {this.setState({cpa: event.target.value})}} placeholder="Old Password" />
                        <input type="password" onChange={(event) => {this.setState({npa: event.target.value})}} placeholder="New Password" />
                        <button onClick={(e) => {
                            e.preventDefault()
                            if (this.state.cpa && this.state.npa) {
                                this.setState({isLoading: true})
                                var user = firebase.auth().currentUser
                                var cred = firebase.auth.EmailAuthProvider.credential(this.state.email, this.state.cpa)
                                user.reauthenticateWithCredential(cred).then(() => {
                                    user.updatePassword(this.state.npa).then(() => {
                                        alert("Password updated")
                                        this.setState({isLoading: false, cpa: "", npa: "", lip: "Password updated"})
                                    }).catch((error) => { this.setState({msg: error.message, isLoading: false}) });
                                }).catch((error) => { this.setState({msg: error.message, isLoading: false}) });
                            } else {
                                this.setState({cp: !this.state.cp})
                            }
                        }}>
                            {
                                this.state.isLoading ?
                                <div className="lds-dual-ring"></div>
                                :"Submit"
                            }
                            </button>
                    </div>
                </div>
            </section>
            {
                this.state.gf ?
                <div className="mr">
                    <div className="close">
                        <div></div>
                        <div onClick={() => this.setState({gf: false})}>x</div>
                    </div>
                    <div className="kij">
                        <Link to="/Admin1496"><span>Home</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Analytics"><span>Analytics</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/Students"><span>Update Fee</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsOwing"><span>Students Owing</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/StudentsCompleted"><span>Completed Students</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <Link to="/ChangePassword"><span>Change Password</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></Link>
                        <div onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    window.location.href="/AdminLogin"
                                })
                            }} className="lo" style={{marginTop: 30}}><span>Logout</span><span><VscLinkExternal size="12" color="rgb(72, 255, 0)" /></span></div>
                    </div>
                </div>:null
            }
        </div>
    )
    // v=spf1 +a +mx +ip4:192.145.239.48 include:smtp.servconfig.com ~all; 
    // 144.208.71.115
}
}

export default Change;